<template>
  <v-card :loading="loading" outlined>
    <!-- USER -->
    <v-card-text>
      <div class="subtitle-1 d-inline pa-2 grey lighten-3 black--text">
        <span v-if="user.is_google_admin === true">Administrador</span>
        <span v-else>Usuário</span>
      </div>
    </v-card-text>
    <v-card-actions class="ml-2">
      <v-avatar>
        <img
          v-if="user.photo == '' || user.photo == null"
          src="@/assets/user-default.png"
        />
        <img v-else :src="user.photo" />
      </v-avatar>
      <div class="ms-2 ajust-name-responsive-style font-weight-medium">
        {{ user.name }}
      </div>
    </v-card-actions>
    <v-card-text>
      <div class="ms-0 pb-1 black--text">{{ user.email }}</div>
      <div class="ms-0">
        <span
          class="red--text font-weight-medium"
          v-if="user.is_suspended == true"
          >Suspenso</span
        >
        <span class="green--text font-weight-medium" v-else>Ativo</span>
      </div>
      <div>
        <span class="ms-0">Último login em </span>
        <span v-if="checkIfNeverLoggedIn(user.lastacess)">
          {{ $t("techDiagnosis.generalRow.neverLogged") }}
        </span>
        <span v-else class="ms-0">{{
          formatDateAndHour(user.last_login)
        }}</span>
      </div>
    </v-card-text>

    <!-- EDITAR TAGS -->
    <v-list-item @click="havePermission">
      <v-list-item-icon style="max-width: 24px" class="mr-3">
        <v-icon slot="prependIcon" v-text="'mdi-pencil'" />
      </v-list-item-icon>
      <v-list-item-title
        class="text-md-body-2 font-weight-medium grey--text text--darken-1"
      >
        Editar informações do usuário
      </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <!-- UO -->
    <v-card-text>
      Unidade Organizacional
      <div class="pt-1 mt-1 subtitle-2 font-weight-black">
        <span v-if="user.ou == '/'">Principal</span>
        <span v-else>{{ user.ou.slice(1) }}</span>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <!-- APLICAÇÕES DO USUÁRIO -->
    <v-card-text>
      Aplicações do usuário
      <v-progress-linear
        v-if="loadingApps"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-list v-else dense>
        <v-list-item v-if="!userAppsShown.length && appsNotFound">
          Sem aplicações.
        </v-list-item>

        <v-list-item
          v-else-if="!userAppsShown.length"
          @click="syncUserApplications"
        >
          <v-list-item-icon style="max-width: 24px" class="mr-3">
            <v-icon slot="prependIcon" v-text="'mdi-application-import'" />
          </v-list-item-icon>
          <v-list-item-title
            class="text-md-body-2 font-weight-medium grey--text text--darken-1"
          >
            RECARREGAR APLICAÇÕES
          </v-list-item-title>
        </v-list-item>
        <v-list-item-group v-else class="px-0 mx-0" style="cursor: pointer">
          <v-list-item
            v-for="(app, index) in userAppsShown"
            @click="showHideDialogUserApps = true"
            :key="index"
          >
            <v-list-item-avatar :rounded="false" size="24" class="mr-8">
              <Logo :product="app.product" height="24" noMargins />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                :title="app.name"
                v-text="app.name"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="Object.keys(userApps).length > 4"
            @click="showHideDialogUserApps = true"
          >
            <v-list-item-content>
              <v-list-item-title>Ver mais...</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-card-text v-if="userAppsMessage" v-html="userAppsMessage" />
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <!-- PERMISSÕES DO USUÁRIO -->
    <v-card-text>
      <br v-if="!currentUserCanChangesPermissionUsers" />
      Permissões do usuário
      <v-list
        dense
        style="cursor: pointer"
        v-if="currentUserCanChangesPermissionUsers"
      >
        <v-list-item-group class="px-0 mx-0">
          <v-list-item
            v-if="changeStatusAdmin"
            class="mt-5 d-flex justify-center"
          >
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-list-item>
          <v-list-item
            v-else
            @click="modalUserPermission = true"
            v-for="(prod, key) in appItems"
            :key="key"
          >
            <v-list-item-avatar v-if="!!prod.image" size="20" class="pl-0 ml-0">
              <v-img :src="prod.image" contain />
            </v-list-item-avatar>
            <v-list-item-avatar
              v-if="!!prod.image"
              size="20"
            ></v-list-item-avatar>
            <v-list-item-icon v-else>
              <v-icon v-text="prod.icon" size="20" color="grey darken-1" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="prod.title"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon :color="userPermission[prod.code] ? 'success' : 'red'">{{
                userPermission[prod.code]
                  ? "mdi-check-circle"
                  : "mdi-close-circle"
              }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list v-else>
        <div class="py-1 my-1 subtitle-2 font-weight-black">
          <span>Sem permissão de visualização</span>
        </div>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <!-- EDITAR TAGS -->
    <v-list-item @click="havePermission">
      <v-list-item-icon style="max-width: 24px" class="mr-3">
        <v-icon slot="prependIcon" v-text="'mdi-tag'" />
      </v-list-item-icon>
      <v-list-item-title
        class="text-md-body-2 font-weight-medium grey--text text--darken-1"
      >
        EDITAR INFORMAÇÕES DO USUÁRIO
      </v-list-item-title>
    </v-list-item>

    <DialogEditUserTags
      @close="close"
      :user="user"
      :show="showUserCardSignature"
    />
    <!-- DIALOG DE PERMISSÕES DO USUÁRIO -->
    <v-dialog v-model="modalUserPermission" max-width="750px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="modalUserPermission = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Editar permissões de {{ user.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row class="d-flex justify-center mt-2">
            <AlertVue>
              <span class="font-weight-bold">ATENÇÃO:</span> Essa configuração
              não altera as permissões do Google Admin Console.
            </AlertVue>
          </v-row>

          <v-list dense>
            <v-list-item>
              <v-list-item-content>Produto </v-list-item-content>
              <v-list-item-action class="me-1">Gerente</v-list-item-action>
            </v-list-item>
            <v-list-item v-for="(appItem, i) in appItems" :key="i">
              <v-list-item-avatar v-if="!!appItem.image" size="30"
                ><v-img :src="appItem.image" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-avatar v-if="!!appItem.image" size="15">
              </v-list-item-avatar>
              <v-list-item-icon v-else>
                <v-icon v-text="appItem.icon" size="30" color="grey darken-1" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="appItem.title"> </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  :color="userPermission[appItem.code] ? 'success' : 'red'"
                  v-model="userPermission[appItem.code]"
                  @click="
                    switchPermission(
                      user.email,
                      appItem.title,
                      userPermission[appItem.code]
                    )
                  "
                  hide-details
                  :readonly="user.is_google_admin || changeStatusAdmin"
                  :loading="changeStatusAdmin"
                  inset
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DialogHasProductAccess
      v-if="chosenMenuItem"
      :show="showProductDialog"
      @close="showProductDialog = false"
      :selectedProduct="chosenMenuItem"
      :status="menuItemStatus"
    />

    <!-- DIALOG DE APLICAÇÕES DO USUÁRIO -->
    <DialogUserApps
      :user="user"
      :showHide="showHideDialogUserApps"
      @close="showHideDialogUserApps = false"
      :userApps="userApps"
      :superLoading="loadingApps"
      @userApps="userApps = $event"
      @syncApps="syncUserApplications"
    ></DialogUserApps>
  </v-card>
</template>

<script>
import DialogEditUserTags from "@/components/sign/tags/DialogEditUserTags.vue";
import DialogUserApps from "@/components/user/DialogUserApps.vue";
import { formatDateAndHour } from "@/helpers/services/utils";
import { checkIfNeverLoggedIn } from "@/helpers/services/users";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AlertVue from "@/components/base/Alert.vue";
import { modules } from "@/helpers/variables/modules";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { getModule } from "@/helpers/services/userPermissions";
import { appSlugs } from "@/helpers/variables/itemsNApps";

export default {
  name: "ComponentCardUser",
  props: ["user"],
  components: { DialogEditUserTags, AlertVue, DialogUserApps },
  data() {
    return {
      loading: false,
      showUserCardSignature: false,
      chosenMenuItem: "",
      menuItemStatus: "",
      showProductDialog: false,
      modalUserPermission: false,
      appItems: [],
      items: [],
      userPermission: {},
      changeStatusAdmin: false,
      hasChangeUserPermission: false,
      errorMessages,
      successMessages,
      loadingApps: false,
      userApps: {},
      showHideDialogUserApps: false,
      userAppsMessage: null,
      appsNotFound: false,
      appSlugs,
    };
  },

  computed: {
    ...mapGetters([
      "hasProductPermission",
      "activeProducts",
      "disabledTrialProducts",
      "currentUser",
      "contractedPlans",
      "token",
      "hasConectaTrack",
      "isReseller",
      "hasConectaSign",
      "hasSignPermission",
      "hasManageAdminsPermission",
    ]),
    currentUserCanChangesPermissionUsers() {
      return (
        this.currentUser.permission.manage_admins == "admin" ||
        this.currentUser.is_google_admin
      );
    },
    userAppsShown() {
      return Object.values(this.userApps).slice(0, 4);
    },
    logoSlug() {
      return (app_name) => this.appSlugs[app_name] || "application";
    },
  },

  methods: {
    checkIfNeverLoggedIn,
    ...mapActions(["getContractedPlans", "getCurrentUser"]),
    ...mapMutations(["setSnackBar", "setFunctionalityClicked"]),
    formatDateAndHour,
    getModule,
    switchPermission(email, nameModule, permission) {
      if (!this.user.is_google_admin) {
        this.updateUser(
          email,
          nameModule,
          permission ? "admin" : "collaborator"
        );
      }
    },
    close() {
      this.showUserCardSignature = false;
    },
    goToAdminFunctions() {
      this.$router.push({
        path: "/admin",
        query: { currentUser: this.currentUser },
      });
    },
    havePermission() {
      const product = "conecta_sign";
      if (this.hasProductPermission[product] === "ACCESS_ALLOWED") {
        this.showUserCardSignature = true;
      } else {
        this.menuItemStatus = this.hasProductPermission[product];
        this.chosenMenuItem = product;
        this.showProductDialog = true;
        this.setFunctionalityClicked("Tags do usuário");
      }
    },
    async setAppItems() {
      this.changeStatusAdmin = true;
      await this.getContractedPlans();

      let product_names = this.contractedPlans.reduce((acc, company_plan) => {
        const { products } = company_plan.plan;

        acc.push(...products.map((product) => product.name));
        return acc;
      }, []);

      if (this.isReseller) {
        product_names.push(modules.reseller);
      }

      product_names.push(modules.manageAdmins);
      product_names.push(modules.inventory);
      product_names.push(modules.companyProfile);
      product_names.push(modules.billing);
      product_names.push(modules.reports);

      new Set(product_names).forEach((product_name) => {
        if (product_name === modules.suite) {
          this.appItems.push({
            title: "Conecta Suite",
            code: modules.suite,
            image: require("@/assets/appslogos/icons/icon-suite.png"),
            path: "/",
          });
        } else if (product_name === modules.track && this.hasConectaTrack) {
          this.appItems.push({
            title: "Conecta Track",
            code: modules.track,
            image: require("@/assets/appslogos/icons/icon-track.png"),
            path: "/",
          });
        } else if (product_name === modules.sign) {
          this.appItems.push({
            title: "Conecta Sign",
            code: modules.sign,
            image: require("@/assets/appslogos/icons/icon-sign.png"),
            path: "/",
          });
        } else if (product_name === modules.transfer) {
          this.appItems.push({
            title: "Conecta Transfer",
            code: modules.transfer,
            image: require("@/assets/appslogos/icons/icon-transfer.png"),
            path: "/",
          });
        } else if (product_name === modules.support) {
          this.appItems.push({
            title: "Conecta Support",
            code: modules.support,
            image: require("@/assets/appslogos/icons/icon-support.png"),
            path: "/",
          });
        } else if (product_name === modules.reseller) {
          this.appItems.push({
            title: "Conecta Partners",
            code: modules.reseller,
            icon: "mdi-bank",
            path: "/",
          });
        } else if (product_name === modules.manageAdmins) {
          this.appItems.push({
            title: "Permissões de Usuários",
            code: modules.manageAdmins,
            icon: "mdi-account-key",
            path: "/",
          });
        } else if (product_name === modules.inventory) {
          this.appItems.push({
            title: "Inventário digital",
            code: modules.inventory,
            icon: "mdi-application",
            path: "/",
          });
        } else if (product_name === modules.companyProfile) {
          this.appItems.push({
            title: "Perfil da Empresa",
            code: modules.companyProfile,
            icon: "mdi-store",
            path: "/",
          });
        } else if (product_name === modules.billing) {
          this.appItems.push({
            title: "Faturamento",
            code: modules.billing,
            icon: "mdi-credit-card-outline",
            path: "/",
          });
        } else if (product_name === modules.reports) {
          this.appItems.push({
            title: "Relatórios",
            code: modules.reports,
            icon: "mdi-poll",
            path: "/",
          });
        }
      });
      if (this.appItems.length) {
        this.cardTitle = this.appItems[0].title;
        this.cardImg = this.appItems[0].image;
        this.cardIcon = this.appItems[0].icon;
        this.getSpecialUserByModule(this.appItems[0].code);
      }
      this.changeStatusAdmin = false;
    },
    getSpecialUserByModule(product) {
      this.changeStatusAdmin = true;
      this.$axios
        .get(process.env.VUE_APP_API_BASE_URL + "/admin-users", {
          params: { product: product, allUsers: true },
          headers: {
            Authorization: this.token,
          },
        })
        .then(({ data }) => {
          let formatedArray = [];
          for (let i = 0; i < data.length; i++) {
            if (this.user.email == data[i].email) {
              formatedArray.push({
                email: data[i].email,
                type: data[i].is_google_admin,
                image: data[i].photo,
              });
              if (!this.user.is_google_admin) {
                this.userPermission = {
                  conecta_suite:
                    data[i].permission.conecta_suite == "admin" ? true : false,
                  conecta_sign:
                    data[i].permission.conecta_sign == "admin" ? true : false,
                  conecta_track:
                    data[i].permission.conecta_track == "admin" ? true : false,
                  reseller:
                    data[i].permission.reseller == "admin" ? true : false,
                  manage_admins:
                    data[i].permission.manage_admins == "admin" ? true : false,
                  inventory:
                    data[i].permission.conecta_sign == "admin" ? true : false,
                  company_profile:
                    data[i].permission.company_profile == "admin"
                      ? true
                      : false,
                  billing: data[i].permission.billing == "admin" ? true : false,
                  reports: data[i].permission.reports == "admin" ? true : false,
                };
              } else {
                this.userPermission = {
                  conecta_suite: true,
                  conecta_sign: true,
                  conecta_track: true,
                  reseller: true,
                  manage_admins: true,
                  inventory: true,
                  company_profile: true,
                  billing: true,
                  reports: true,
                };
              }
            }
          }
          this.items = formatedArray;
          this.items[0].permission = this.userPermission;
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
        })
        .finally(() => {
          this.changeStatusAdmin = false;
        });
    },
    async updateUser(email, nameModule, permission) {
      this.changeStatusAdmin = true;
      let product = getModule(nameModule);
      let body = {
        product: product,
        permission: permission,
        modify_user: email,
      };

      await this.callAPI(body);
    },
    async callAPI(body) {
      let auth = { headers: { Authorization: this.token } };

      await this.$axios
        .put(`${process.env.VUE_APP_API_BASE_URL}/admin-users`, body, auth)
        .then(() => {
          if (body.modify_user === this.currentUser.email) {
            this.getCurrentUser();
          }
          this.getSpecialUserByModule(body.product);
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          this.setSnackBar({
            color: "error",
            message: "Erro ao mudar permissão de usuário",
            show: true,
          });
          this.getSpecialUserByModule();
        })
        .finally(() => {
          this.changeStatusAdmin = false;
        });
    },
    getUserApplications() {
      this.loadingApps = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user.key}/applications`;
      const headers = { Authorization: this.token };
      this.$axios
        .get(url, { headers })
        .then((response) => {
          const data = response.data;
          this.userApps = Object.fromEntries(
            data.map((app) => [
              app.name,
              { ...app, product: this.logoSlug(app.name) },
            ])
          );
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          if (err.response.status === 403) {
            this.userAppsMessage = "Sem permissão de visualização";
          } else {
            this.userAppsMessage =
              errorMessages[code] || errorMessages["unknown"];
          }
        })
        .finally(() => {
          this.loadingApps = false;
        });
    },
    syncUserApplications() {
      this.loadingApps = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user.key}/sync-applications`;
      const headers = { Authorization: this.token };
      this.$axios
        .post(url, null, { headers })
        .then((response) => {
          const data = response.data;
          this.userApps = Object.fromEntries(
            data.map((app) => [
              app.name,
              { ...app, product: this.logoSlug(app.name) },
            ])
          );
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          if (err.response.status === 403) {
            this.userAppsMessage = "Sem permissão de visualização";
          } else {
            this.userAppsMessage =
              errorMessages[code] || errorMessages["unknown"];
          }
        })
        .finally(() => {
          this.loadingApps = false;
        });
    },
  },

  beforeMount() {
    if (this.hasManageAdminsPermission) this.setAppItems();
    if (this.hasConectaSign && this.hasSignPermission)
      this.getUserApplications();
  },
};
</script>

<style scoped>
#user-permission-text {
  font-size: small !important;
}
</style>
