<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple>
      <v-expansion-panel :class="`${outlined ? 'outlined' : ''}`">
        <v-progress-linear
          v-if="showLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <v-row align-content="center">
            <v-col class="d-block" cols="9">
              <div class="mx-0 pt-0 px-0">
                {{ $t("common.addUserToGroups") }}
              </div>
              <small class="mx-0 pt-0 px-0">{{
                $t("common.workspaceGroups")
              }}</small>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          :class="`pb-0 ${overlayScopes ? 'pa-15' : ''}`"
          style="position: relative"
        >
          <v-card-text class="mx-0 pt-0 px-0">
            {{ $t("common.workspaceGroupsDescription") }}
          </v-card-text>

          <v-simple-table class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in userGroups" :key="i">
                  <td>{{ item }}</td>

                  <td align="end">
                    <v-btn
                      text
                      small
                      class="ma-2"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogDeleteGroup = true), (groupEmail = item)
                      "
                      >{{ $t("action.delete") }}</v-btn
                    >

                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogDeleteGroup"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.1"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">
                          {{ $t("common.confirmDeletion") }}
                        </h2>
                        <v-card-text>
                          {{ $t("common.confirmGroupDeletion") }}:
                          <strong>{{ groupEmail }}</strong
                          >?</v-card-text
                        >
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="reset()"
                            :loading="showLoading"
                          >
                            {{ $t("action.cancel") }}
                          </v-btn>
                          <v-btn
                            color="#311F50"
                            text
                            @click="removeGroup()"
                            :loading="showLoading"
                            >{{ $t("action.confirm") }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div style="width: fit-content">
            <v-btn
              :color="`${isOnboardingPage ? 'primarySuite' : 'accent'}`"
              @click="showDialogAddGroup = true"
              :disabled="showLoading || overlayScopes"
              :loading="showLoading"
              class="mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text"
            >
              {{ $t("action.addGroup") }}
            </v-btn>
          </div>

          <!-- DIALOG ADC NOVO GRUPO -->
          <HeaderDialog
            id="group_to_user"
            :title="$t('action.addGroup')"
            :show="showDialogAddGroup"
            width="600"
            :loading="showLoading"
            :disabled-action="!groupEmail"
            @action="addUserToGroup()"
            @close="reset()"
            :persistent="false"
          >
            <template v-slot:body>
              <p class="pb-2 pt-3">{{ $t("common.addGroupDescription") }}:</p>

              <v-autocomplete
                v-model="groupEmail"
                :items="groupsUserAreNotIn"
                :placeholder="'meugrupo@' + mainDomain"
                class="mt-0"
              ></v-autocomplete>
              <div v-if="!noSendAs" class="d-flex" style="align-items: end">
                <v-checkbox
                  v-model="addSendAs"
                  :disabled="!userHasGmail || userHasThisSendAs"
                  hide-details
                  :label="`${$t('common.saveAsSecondaryEmail')} (${$t(
                    'common.sendEmailAs'
                  )})`"
                  class="mr-3"
                />
                <tooltip-icon
                  v-if="!!tooltipMessage"
                  icon="mdi-information"
                  :label="tooltipMessage"
                  bottom
                />
              </div>
            </template>
          </HeaderDialog>
          <!-- OVERLAY FOR SCOPES MISSING  -->
          <OverlayScopes
            :overlay="overlayScopes"
            :title="$t('common.addUserToGroups')"
            :functionality="$t('common.addUserToGroups')"
            dense
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import TooltipIcon from "../base/TooltipIcon.vue";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "UserGroups",
  components: { HeaderDialog, TooltipIcon, OverlayScopes },
  props: {
    user: { type: Object, default: () => {} },
    outlined: { type: Boolean, default: false },
    noSendAs: { type: Boolean, default: false },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      showLoading: false,

      items: [],
      showDialogAddGroup: false,
      showDialogDeleteGroup: null,

      userGroups: [],
      groupEmail: "",
      addSendAs: false,
      overlayScopes: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "groupEmails", "mainDomain"]),

    userHasNotEvaluated() {
      if (this.currentUser.feedbacks) {
        return !this.currentUser.feedbacks.send_as_email;
      }
      return false;
    },

    userHasGmail() {
      return this.user?.is_gmail_enabled;
    },

    groupsUserAreNotIn() {
      return this.groupEmails.filter((item) => !this.userGroups.includes(item));
    },

    userHasThisSendAs() {
      if (this.userHasGmail && this.user?.send_as.length) {
        return !!(this.user?.send_as || []).find(
          (item) => item.email === this.groupEmail
        );
      }
      return false;
    },

    tooltipMessage() {
      if (this.userHasThisSendAs)
        return this.$t("common.groupAliasAlreadyInUseforUser");
      else if (!this.userHasGmail)
        return this.$t("common.requiredEmailService");
      else return "";
    },

    isOnboardingPage() {
      if (this.$route.name === "Onboarding") return true;
      return false;
    },
  },

  methods: {
    ...mapActions([
      "insertUserOnGroup",
      "removeUserFromGroup",
      "getGroupsByUser",
      "getGoogleWorkspaceGroups",
    ]),
    ...mapMutations(["setSnackBar"]),

    addUserToGroup() {
      this.showLoading = true;

      let payload = {
        email: this.user?.email,
        group: this.groupEmail,
      };

      this.insertUserOnGroup(payload)
        .then(() => {
          this.userGroups.push(this.groupEmail);
          localStorage.setItem("userGroups", JSON.stringify(this.userGroups));
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
          });
          let data = {
            email: this.groupEmail,
            name: this.groupEmail.split("@")[0],
            id_user: this.user?.id_google,
          };
          if (this.addSendAs) return this.$emit("addSendAs", data);
        })
        .catch((error) => {
          console.log("insertUserOnGroup()", error);
          const { GROUP_CONFLICT, unknown } = errorMessages;
          console.log("insertUserOnGroup()", error);
          if (error.response.status === 409) {
            this.userGroups.push(this.groupEmail);
            localStorage.setItem("userGroups", JSON.stringify(this.userGroups));
            this.setSnackBar({
              show: true,
              message: GROUP_CONFLICT,
              color: "error",
            });
          } else if (
            error.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN"
          ) {
            this.overlayScopes = true;
            return;
          } else {
            this.setSnackBar({
              show: true,
              message: unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
        });
    },

    removeGroup() {
      this.showLoading = true;

      let payload = {
        email: this.user?.email,
        group: this.groupEmail,
      };

      this.removeUserFromGroup(payload)
        .then(() => {
          this.userGroups = this.userGroups.filter(
            (email) => email !== this.groupEmail
          );
          localStorage.setItem("userGroups", JSON.stringify(this.userGroups));

          this.setSnackBar({
            show: true,
            message: successMessages.delete_24hours,
          });
        })
        .catch((err) => {
          console.log("removeGroup()", err);
          if (err.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.overlayScopes = true;
            return;
          } else {
            this.setSnackBar({
              show: true,
              message: errorMessages.unknown,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.reset();
        });
    },

    reset() {
      this.showDialogAddGroup = false;
      this.showDialogDeleteGroup = false;
      this.groupEmail = "";
      this.showLoading = false;
      this.addSendAs = false;
    },

    async setGroups() {
      this.showLoading = true;
      if (!this.groupEmails.length) await this.getGoogleWorkspaceGroups();
      if (this.user?.key) {
        this.getGroupsByUser(this.user)
          .then(({ data }) => {
            this.userGroups = data;
          })
          .catch((err) => {
            console.log("removeGroup()", err);
            if (err.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
              this.overlayScopes = true;
              return;
            } else if (err.response.data.code) {
              this.setSnackBar({
                is_key: !!err.response.data.code,
                message_key: err.response?.data?.code,
                show: true,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.reset();
          });
      } else {
        this.showLoading = false;
      }
    },
  },

  watch: {
    user() {
      this.setGroups();
    },
    panel() {
      if (!this.groupEmails.length || !this.userGroups.length) {
        this.setGroups();
      }
    },
  },

  mounted() {
    if (this.isOnboardingPage) this.setGroups();
  },
};
</script>
