<template>
  <v-container fluid>
    <v-row class="px-2 pt-3 ma-0">
      <v-col cols="12" sm="6" class="py-0 my-0 px-0" v-if="kind == 'user'">
        <v-autocomplete
          v-model="currentSelectedEmail"
          :loading="showLoading || loading || customLoading"
          :items="allEmails"
          outlined
          :color="secondaryColor"
          item-color="grey darken-1"
          label="E-mail"
          placeholder="Selecione um endereço de e-mail"
          item-text="email"
          item-value="email"
          class="px-1"
        ></v-autocomplete>
      </v-col>

      <v-col
        v-if="!hasConectaSign || !hasSignPermission"
        cols="12"
        sm="6"
        class="py-0 my-0 px-0"
      >
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" color="white" max-width="600" elevation="0">
            <v-autocomplete
              :disabled="!hasConectaSign"
              item-text="name"
              item-value="id"
              label="Modelo da assinatura"
              placeholder="Selecione um modelo"
              item-color="grey darken-1"
              :color="secondaryColor"
              outlined
              class="px-1"
              hide-details
            ></v-autocomplete>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal body-1 white--text"
                style="height: 100%"
              >
                <span v-if="!hasConectaSign" class="pa-2">
                  Templates indisponíveis para o seu plano
                </span>
                <span v-else-if="!hasSignPermission" class="pa-2">
                  Você não tem permissão para acessar os templates.
                </span>
                <span v-else> Não foi possível carregar os templates. </span>
              </div>
            </v-expand-transition>
          </v-card>
        </v-hover>
      </v-col>

      <v-col v-else cols="12" sm="6" class="py-0 my-0 px-0">
        <v-autocomplete
          :loading="showLoading || loading || customLoading"
          v-model="idTemplateSelected"
          :items="allSignatureTemplates"
          :disabled="!hasConectaSign"
          item-text="name"
          item-value="id"
          label="Modelo da assinatura"
          placeholder="Selecione um modelo"
          item-color="grey darken-1"
          :color="secondaryColor"
          outlined
          class="px-1"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="pl-4 pr-2 mt-0">
      <v-col
        class="ma-0"
        v-if="
          (htmlTemplateSelected === null || htmlTemplateSelected === '') &&
          !isConectaSuiteView
        "
      >
        Selecione um modelo para pré-visualizar a assinatura abaixo.
      </v-col>

      <v-col v-else>
        Pré-visualização da assinatura do usuário
        <strong v-if="user && user.name"
          >{{ user.name }} ({{ currentSelectedEmail }})</strong
        >
      </v-col>
    </v-row>
    <v-row class="px-6" v-if="htmlTemplateSelected !== null">
      <v-alert dense color="error" v-if="!user.email" class="body-2">
        A unidade organizacional selecionada não possui
        <strong>nenhum usuário</strong> associado.
      </v-alert>
      <v-alert
        v-if="!templateHtml && !loading"
        icon="mdi-alert"
        color="error"
        dense
        dark
        style="width: 100%"
        class="text-body-2 py-1"
      >
        Esse modelo está em branco
        <span v-if="hasConectaSign">
          para editá-lo vá para a tela de
          <v-btn
            text
            x-small
            dark
            target="_blank"
            to="/sign/template"
            class="pb-1 px-0 text-none text-body-2 font-weight-medium"
            >modelos</v-btn
          >
          ou
          <v-btn
            text
            x-small
            dark
            target="_blank"
            to="/sign/template"
            class="pb-1 px-0 text-none text-body-2 font-weight-medium"
            >clique aqui</v-btn
          >.
        </span>
      </v-alert>
    </v-row>
    <v-card min-height="180" class="mx-3" outlined>
      <v-progress-linear
        :color="secondaryColor"
        v-if="showLoading || loading || customLoading"
        indeterminate
      ></v-progress-linear>
      <v-card
        v-if="!loading"
        class="mx-2 my-2 px-4 py-2 overflow-x-auto overflow-y-auto"
        v-html="templateHtml"
        flat
        :height="isConectaSuiteView ? 360 : 'auto'"
      />
    </v-card>

    <Alert
      v-if="
        hasConectaSignTemplateSelected &&
        (hasConectaSignAndButCanNotEdit || !hasConectaSign)
      "
      class="py-2 text-body-2 mt-4 mx-4"
      :prominent="false"
      color="warning"
      dense
    >
      <span v-if="!hasConectaSign">
        As alterações de modelo não podem ser salvas pois sua empresa
        <b>não possui o plano Standard ou mais contratado</b> para aplicar um
        modelo de assinatura.
      </span>
      <span v-if="hasConectaSign && !hasSignPermission">
        Você não possui permissão para aplicar modelos de assinatura a usuários.
        Solicite permissão a um administrador
      </span>
      <v-btn
        v-if="!hasConectaSign && (isAdmin || hasBillingPermission)"
        text
        x-small
        class="text-none text-wrap text-body-2 font-weight-medium px-0 py-0 my-0 pb-1"
        color="warning darken-2"
        to="/billing/add-plan"
        >Confira nossas opções de planos
        <v-icon small v-text="'mdi-open-in-new'" bottom />
      </v-btn>
      <v-btn
        v-if="!hasConectaSign && !isAdmin && !hasBillingPermission"
        text
        x-small
        class="text-none text-wrap text-body-2 font-weight-medium px-0 py-0 my-0 pb-1"
        color="warning darken-2"
        href="https://conectasuite.com/precos/"
        >Confira nossas opções de planos
        <v-icon small v-text="'mdi-open-in-new'" bottom />
      </v-btn>
      <span v-if="!hasConectaSign || !hasSignPermission">
        ou personalize manualmente uma assinatura para este usuário
      </span>
      <v-btn
        text
        v-if="!hasConectaSign || !hasSignPermission"
        x-small
        class="text-none text-wrap text-body-2 font-weight-medium px-0 py-0 my-0 pb-1"
        color="warning darken-2"
        @click="$emit('changeToEdit')"
        >clicando aqui</v-btn
      >.
    </Alert>

    <!-- BOTÃO APLICAR ASSINATURA -->
    <v-btn
      v-if="hasConectaSignTemplateSelected"
      class="ma-3 text-none text-subtitle-2 white--text"
      elevation="0"
      :color="isConectaSuiteView ? 'accent' : 'accent'"
      @click="dialogApplySignature = true"
      light
      :disabled="
        !currentSelectedEmail ||
        canNotEdit ||
        showLoading ||
        loading ||
        customLoading ||
        hasConectaSignAndButCanNotEdit
      "
    >
      <v-icon
        disabled
        v-if="canNotEdit && isConectaSuiteView"
        v-text="'mdi-lock'"
        small
        left
      />
      Aplicar assinatura
    </v-btn>

    <!-- BOTÃO EDITAR MODELO -->
    <v-btn
      v-if="
        !hasConectaSignTemplateSelected &&
        hasSuitePermission &&
        isConectaSuiteView
      "
      class="ma-3 text-none text-subtitle-2"
      color="grey darken-2"
      text
      @click="$emit('changeToEdit')"
      outlined
      :disabled="showLoading || loading || customLoading"
      >Editar modelo em HTML</v-btn
    >

    <v-btn
      v-if="hasConectaSignTemplateSelected"
      class="ma-3 text-none text-subtitle-2"
      :disabled="
        this.signatureType !== 'custom' ||
        !hasSignPermission ||
        showLoading ||
        loading ||
        customLoading ||
        !hasConectaSign ||
        hasConectaSignAndButCanNotEdit
      "
      color="grey darken-2"
      text
      @click="goTemplatePage()"
      outlined
    >
      <v-icon v-text="'mdi-pencil'" left dense />
      Editar modelo {{ isConectaSuiteView ? "em nosso editor" : "" }}
    </v-btn>

    <HeaderDialog
      :show="dialogApplySignature"
      id="confirm_new_user_signature"
      :title="
        isUnidadeOrganizacional
          ? orgUnitName
          : `${user.name} (${currentSelectedEmail})`
      "
      subtitle="Confirmar modelo de assinatura"
      :color="primaryColor"
      @close="dialogApplySignature = false"
      @action="applySignature"
      :loading="loading"
    >
      <template v-slot:body>
        <p>
          Tem certeza que deseja aplicar essa assinatura
          <span v-if="kind == 'user'"
            >no e-mail <b>{{ currentSelectedEmail }}</b> do usuário
            <b>{{ user.name }}</b
            >?</span
          >
          <span v-else>
            para todos os usuários da Unidade Organizacional selecionada?</span
          >
        </p>

        <Alert dense color="warning" class="mb-0">
          Esta alteração pode levar até 10 minutos para ser efetivada.
        </Alert>
      </template>
    </HeaderDialog>

    <!-- SNACKBAR -->
    <Snackbar
      :show="showSnackBarFailure"
      :message="errorMessages.unknown"
      color="error"
      @close="showSnackBarFailure = false"
    />

    <v-snackbars bottom :objects.sync="messages" multi-line>
      <template v-slot:default="{ message }">
        {{ message }}

        <v-progress-circular
          v-if="message.startsWith('Dentro de alguns minutos')"
          indeterminate
          :width="2"
          :size="18"
          class="ml-2"
        ></v-progress-circular>
      </template>
      <template v-slot:action="{}"> <span /> </template>
    </v-snackbars>
  </v-container>
</template>

<script>
import { fluxTemplateToPreview } from "@/helpers/services/tagPreview";
import { mapActions, mapGetters } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";

export default {
  name: "SignPreview",

  props: {
    user: { type: Object, default: () => {} },
    orgUnit: { type: String, default: "" },
    kind: { type: String, default: "" },
    view: { type: String, default: "conecta_sign" },
    customLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      errorMessages,
      successMessages,
      dialogApplySignature: false,
      showLoading: false,
      signatureType: "",
      idTemplateSelected: {
        id: null,
        name: "Assinatura atual",
      },
      updatedTemplate: "",
      templates: [],
      showSnackBarFailure: false,
      showSnackBarSuccess: false,
      loading: false,
      htmlTemplateSelected: this.html || null,
      selectedEmail: null,
      items: [],
      emails: [],
      messages: [],
    };
  },

  computed: {
    ...mapGetters([
      "company",
      "hasSignPermission",
      "hasConectaSign",
      "isAdmin",
      "secondaryColor",
      "primaryColor",
      "hasSuitePermission",
      "hasBillingPermission",
      "token",
      "allTemplates",
      "hasBasicFreeActive",
    ]),

    currentCustomSignature() {
      const hasCustomSignature = this.emails.find(
        (user) => user.email === this.currentSelectedEmail
      );
      return hasCustomSignature
        ? hasCustomSignature.signature
        : "Nenhuma assinatura definida";
    },

    selectedEmailIsNotPrimaryEmail() {
      return this.currentSelectedEmail !== this.userEmail;
    },

    hasConectaSignAndCanEdit() {
      return this.hasSignPermission === true && this.hasConectaSign === true;
    },

    hasConectaSignAndButCanNotEdit() {
      return !this.hasSignPermission && this.hasConectaSign === true;
    },

    hasConectaSignTemplateSelected() {
      if (this.idTemplateSelected) {
        return Object.keys(this.idTemplateSelected).includes("created_at")
          ? true
          : false;
      }
      return false;
    },

    orgUnitName() {
      return this.orgUnit === "/" ? "Principal" : this.orgUnit.replace("/", "");
    },

    isUnidadeOrganizacional() {
      return this.kind === "uo";
    },

    userEmail() {
      return this.user.email || null;
    },

    userHasGmail() {
      return this.user ? this.user.is_gmail_enabled : false;
    },

    currentSelectedEmail: {
      set(new_value) {
        this.selectedEmail = new_value;
      },
      get() {
        return this.selectedEmail;
      },
    },

    allEmails() {
      return this.emails;
    },

    templateHtml: {
      get() {
        return this.updatedTemplate;
      },
      set(new_value) {
        this.updatedTemplate = this.updateSignature(new_value, this.user);
      },
    },

    canNotEdit() {
      return this.hasConectaSign
        ? this.hasSignPermission
          ? false
          : true
        : true;
    },

    isConectaSuiteView() {
      return this.view === "conecta_suite";
    },

    allSignatureTemplates: {
      get() {
        return this.templates;
      },
      set(new_value) {
        this.templates = new_value;
      },
    },

    userAux: function () {
      if (this.user) {
        return Object.assign({}, this.user);
      }
      return "";
    },
  },

  watch: {
    idTemplateSelected(new_value) {
      if (new_value) {
        this.setUserTemplate(new_value);
      } else {
        this.setUserTemplate();
      }
    },
    currentSelectedEmail() {
      if (this.currentSelectedEmail) {
        this.setUserTemplate();
      }
    },
    user() {
      if (this.user) {
        this.startLoadInfo();
      }
    },

    userAux: {
      handler: async function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue == "") {
            this.templateHtml = this.htmlTemplateSelected;
            this.currentSelectedEmail = null;
          } else {
            this.currentSelectedEmail = this.userEmail;
            await this.getSendAsByUser(this.user);
            this.setUserTemplate();
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["getSendAsByUser", "setSignatureToUsers", "getUsers"]),

    async startLoadInfo() {
      this.loading = true;
      if (Object.keys(this.user).length > 0) {
        this.currentSelectedEmail = this.userEmail;
        const result = await this.getSendAsByUser(this.user);
        if (result === "INVALID_GOOGLE_ACCESS_TOKEN") {
          this.$emit("missingScopes");
          this.emails = [];
          this.loading = false;
          return;
        } else {
          this.emails = result;
        }
      }
      this.setUserTemplate();
      this.loading = false;
    },

    setCurrentSignaturePreview(template) {
      this.idTemplateSelected = Object.assign({}, template);
      this.templateHtml = template.html;
      this.signatureType = template.signature_type;
      this.htmlTemplateSelected = template.html;
      if (this.hasConectaSignAndCanEdit) {
        this.$emit("template", template.html);
      }

      if (this.user == "") {
        this.templateHtml = this.htmlTemplateSelected;
      } else {
        this.templateHtml = this.updateSignature(
          this.htmlTemplateSelected,
          this.user
        );
      }
    },

    setUserTemplate(id = null) {
      if (id) {
        let template = this.allSignatureTemplates.find((c) => c.id === id);

        if (template) {
          this.setCurrentSignaturePreview(template);
        }
      } else if (this.$route.query.id && !this.isConectaSuiteView) {
        let template = this.allSignatureTemplates.find(
          (c) => c.id === this.$route.query.id
        );

        if (template) {
          this.setCurrentSignaturePreview(template);
        }
      } else {
        this.idTemplateSelected = {
          id: null,
          name: "Assinatura atual",
        };
        this.templateHtml = this.currentCustomSignature;
        this.htmlTemplateSelected = this.currentCustomSignature;
      }
    },

    formatTemplateListWithCustomTemplate() {
      this.allSignatureTemplates.push({ header: "Atual" });
      this.allSignatureTemplates.push({
        id: null,
        name: "Assinatura atual",
        html: this.currentCustomSignature,
      });
      this.allSignatureTemplates.push({ header: "Modelos no Conecta Suite" });
    },

    async applySignature() {
      this.loading = true;

      if (this.isUnidadeOrganizacional || this.selectedEmailIsNotPrimaryEmail) {
        const URL =
          process.env.VUE_APP_API_BASE_URL +
          "/create-google-task-for-signature";

        let html = this.htmlTemplateSelected;

        const payload = {
          html: html,
        };

        if (this.kind == "uo") {
          payload["uo"] = this.orgUnit;
        } else if (this.kind == "user") {
          payload["id_user"] = this.user.id_google;
          payload["email"] = this.currentSelectedEmail;
        }

        await this.$axios
          .post(URL, payload, {
            headers: {
              Authorization: this.token,
            },
          })
          .then(() => {
            this.setUserTemplate();
            this.showSnackBarSuccess = true;
            this.dialogApplySignature = false;
          })
          .catch(() => {
            this.showSnackBarFailure = true;
            this.dialogApplySignature = false;
          });
      } else {
        if (this.userEmail && this.idTemplateSelected.id) {
          let payload = {
            emails: [this.userEmail],
            template_key: this.idTemplateSelected.id,
          };
          await this.setSignatureToUsers(payload)
            .then(async () => {
              this.setUserTemplate();
              await this.getUsers();
              /* this.showSnackBarSuccess = true; */
              this.dialogApplySignature = false;
              this.messages.push({
                message: this.successMessages.signature_applied,
                timeout: 3000,
                color: "success",
              });
              setTimeout(() => {
                this.messages.push({
                  message:
                    "Dentro de alguns minutos o modelo aplicado estará disponível",
                  timeout: 10000,
                  color: "info",
                });
              }, 2000);
            })
            .catch(() => {
              this.showSnackBarFailure = true;
              this.dialogApplySignature = false;
            });
        }
      }
      this.loading = false;
    },

    goTemplatePage() {
      if (this.idTemplateSelected) {
        this.$router.push({
          path: "/sign/template",
          query: { id: this.idTemplateSelected.id },
        });
      }
    },

    async getTemplates() {
      this.showLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/signature/templates`;
      const headers = {
        headers: {
          Authorization: this.token,
        },
      };

      await this.$axios
        .get(url, headers)
        .then(({ data }) => {
          const { custom, standard } = data;
          const templates = [].concat(custom, standard);

          this.formatTemplateListWithCustomTemplate();
          if (this.isConectaSuiteView) {
            this.setUserTemplate();
          }
          this.allSignatureTemplates = this.allSignatureTemplates
            .concat(this.allSignatureTemplates, this.sortTemplates(templates))
            .filter((item) => item);
          this.$store.commit("setSignatureTemplates", data);
        })
        .catch(() => {})
        .finally(() => {
          this.showLoading = false;
        });
    },

    sortTemplates(templates) {
      templates.sort((a, b) => {
        if (a.signature_type == "custom" && b.signature_type == "default") {
          return -1;
        }
        return 0;
      });
      return templates;
    },

    updateSignature(original_html, user) {
      if (user && original_html) {
        let html = fluxTemplateToPreview(original_html, user);
        this.$emit("template", html);
        return html;
      } else {
        return original_html;
      }
    },

    goToPlans() {
      this.$router.push({ name: "BillingUpgradePlan" });
    },
  },

  async mounted() {
    if (!this.userHasGmail) return;
    await this.startLoadInfo();
    if (this.hasBasicFreeActive) return;
    if (
      !this.allTemplates.length &&
      this.hasConectaSign &&
      this.hasSignPermission
    ) {
      await this.getTemplates();
    } else {
      this.formatTemplateListWithCustomTemplate();
      if (this.isConectaSuiteView) {
        this.setUserTemplate();
      }
      this.allSignatureTemplates = this.allSignatureTemplates
        .concat(
          this.allSignatureTemplates,
          this.sortTemplates(this.allTemplates)
        )
        .filter((item) => item);
    }
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
</style>
