<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple class="ma-4 ms-1">
      <v-expansion-panel class="outlined">
        <v-progress-linear
          v-if="showLoading == true"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <div>Delegações de Acesso</div>
          <v-spacer />
          <v-play-video-button
            class="mr-2"
            title="Delegação de acesso"
            id_youtube_video="n-FkSIEKA4s"
            justify="end"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content style="position: relative">
          <v-card-text class="mx-0 px-0 pt-0">
            Permita que outras contas tenham acesso à conta deste e-mail e faça
            configurações em nome do usuário
            <span class="font-weight-medium">{{ email }}</span
            >.
          </v-card-text>
          <!-- BOTÃO PARA ADICIONAR OUTRA CONTA -->
          <v-tooltip :disabled="userHasGmail" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: fit-content" v-bind="attrs" v-on="on">
                <v-btn
                  right
                  color="accent"
                  class="mb-3 elevation-0 text-none font-weight-medium text-body-2 white--text"
                  @click="openAddDelegationDialog()"
                  :disabled="showLoading || !userHasGmail"
                  >Adicionar conta</v-btn
                >
              </div>
            </template>
            <span v-if="!userHasGmail">{{
              $t("common.inactiveEmailService")
            }}</span>
          </v-tooltip>

          <v-tabs v-model="tab" grow color="accent">
            <v-tab key="delegates">
              Usuários com acesso à {{ getUserName(email) }}
            </v-tab>
            <v-tab key="access">
              Usuários que {{ getUserName(email) }} possuí acesso</v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="delegates">
              <v-data-table
                class="mx-0 my-4"
                :headers="headers"
                :items="getDelegatedUsers"
                :loading="showLoading"
                hide-default-header
                hide-default-footer
                no-data-text="Não há contas configuradas com acesso a este usuário"
              >
                <template v-slot:[`item.to_user`]="{ item }">
                  <UserBadge
                    :user="getUser(item.to_user)"
                    size="40"
                    simpleView
                  />
                </template>

                <template v-slot:[`item.expire`]="{ item }">
                  <v-col>
                    <p class="my-0" v-if="item.expire_date">
                      Expira em {{ formatDate(item.expire_date) }}
                    </p>
                  </v-col>
                </template>
                <template v-slot:[`item.more`]="{ item }">
                  <v-row>
                    <v-col class="px-0" cols="6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="item"
                            text
                            small
                            color="secondary lighten-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              (dialogChangeExpireDate = true),
                                (expireDate = item.expire_date),
                                setEmailToExpire(item)
                            "
                          >
                            <v-icon> mdi-timer-edit-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar data de expiração da delegação</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="px-0" cols="6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            small
                            color="accent"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            @click="
                              (dialogDeleteItem = true), setEmailToDelete(item)
                            "
                          >
                            <v-icon> mdi-trash-can-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Excluir delegação de acesso</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="access">
              <v-data-table
                class="mx-0 my-4"
                :headers="headers"
                :items="getAccessUsers"
                :loading="showLoading"
                hide-default-header
                hide-default-footer
                no-data-text="Não há contas configuradas com acesso a este usuário"
              >
                <template v-slot:[`item.to_user`]="{ item }">
                  <UserBadge
                    :user="getUser(item.from_user)"
                    size="40"
                    simpleView
                  />
                </template>

                <template v-slot:[`item.expire`]="{ item }">
                  <v-col>
                    <p class="my-0" v-if="item.expire_date">
                      Expira em {{ formatDate(item.expire_date) }}
                    </p>
                  </v-col>
                </template>
                <template v-slot:[`item.more`]="{ item }">
                  <v-row>
                    <v-col class="px-0" cols="6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="item"
                            text
                            small
                            v-bind="attrs"
                            v-on="on"
                            color="secondary lighten-2"
                            @click="
                              (dialogChangeExpireDate = true),
                                (expireDate = item.expire_date),
                                setEmailToExpire(item)
                            "
                          >
                            <v-icon> mdi-timer-edit-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar data de expiração da delegação</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="px-0" cols="6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            small
                            color="accent"
                            v-bind="attrs"
                            v-on="on"
                            dark
                            @click="
                              (dialogDeleteItem = true), setEmailToDelete(item)
                            "
                          >
                            <v-icon> mdi-trash-can-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Excluir delegação de acesso</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>

          <Alert class="py-2 text-body-2" :prominent="false" color="info" dense>
            A delegação de e-mails deve estar ativa no Google Admin Console para
            funcionar efetivamente,
            <v-btn
              text
              x-small
              class="text-none text-wrap text-body-2 font-weight-medium px-1 py-0 my-0 pb-1"
              color="info"
              href="https://admin.google.com/ac/apps/gmail/usersettings"
              target="_blank"
              >clique aqui para configurar
              <v-icon right small v-text="'mdi-open-in-new'" />
            </v-btn>
          </Alert>

          <!-- DIALOG EXCLUIR USER -->
          <HeaderDialog
            v-if="email_to_delete"
            id="delete_access_email"
            :title="isAccessDelegation ? email_to_delete : email"
            subtitle="Revogar acesso de"
            :show="dialogDeleteItem"
            width="600"
            @close="dialogDeleteItem = false"
            @action="deleteDelegate(email_to_delete)"
            :persistent="false"
          >
            <template v-slot:body>
              <p v-if="isAccessDelegation">
                Ao confirmar esta ação, {{ email }} não poderá mais ler e enviar
                mensagens como {{ email_to_delete }}.
              </p>
              <p v-else>
                Ao confirmar esta ação, {{ email_to_delete }} não poderá mais
                ler e enviar mensagens como {{ email }}.
              </p>
              <p>
                Você realmente deseja excluir a delegação de
                <span class="font-weight-medium">{{ email_to_delete }}</span
                >?
              </p>
            </template>
          </HeaderDialog>

          <!-- DIALOG NOVA DATA DE EXPIRAÇÃO -->
          <HeaderDialog
            v-if="dialogChangeExpireDate"
            :show="dialogChangeExpireDate"
            subtitle="Trocar data de expiração para"
            :title="email_to_expire"
            width="600"
            @close="
              (dialogChangeExpireDate = false),
                (expireDate = ''),
                (showNewExpireDate = false)
            "
            @action="
              changeExpireDate(expireDate),
                (dialogChangeExpireDate = false),
                (expireDate = '')
            "
            :persistent="false"
          >
            <template v-slot:body>
              <p>
                Escolha uma nova data de expiração para a delegação de email.
              </p>
              <p>
                Para remover a data de expiração da delegação, deixe o campo de
                data em branco.
              </p>
              <div class="d-flex align-center" v-if="!hasSign">
                <v-icon color="accent" class="ml-0">mdi-crown</v-icon>
                <span class="mt-1 ml-2 font-weight-medium body-2">
                  Data de expiração disponível a partir do plano Stantard
                </span>
              </div>
              <v-menu
                v-model="showNewExpireDate"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <div v-on="!hasSign && onTooltip">
                        <v-text-field
                          class="mt-4"
                          v-model="formatedExpireDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          append-icon="mdi-close"
                          @click:append="expireDate = ''"
                          :disabled="!hasSign"
                        >
                          <template v-slot:label>
                            Data de expiração da delegação
                          </template>
                        </v-text-field>
                      </div>
                    </template>
                    <span>Disponível a partir do plano Standard</span>
                  </v-tooltip>
                </template>
                <v-date-picker
                  v-model="expireDate"
                  locale="pt-br"
                  :min="tomorrow"
                >
                </v-date-picker>
              </v-menu>
            </template>
          </HeaderDialog>

          <!-- DIALOG ADICIONAR NOVO USER -->
          <HeaderDialog
            ref="form"
            id="add_access_email"
            :title="email"
            subtitle="Delegação de acesso"
            :show="dialogAddDelegation"
            width="600"
            @action="createDelegate(), clearDelegationData()"
            :disabled-action="!selectEmail || !fromEmail"
            @close="clearDelegationData()"
            :persistent="false"
          >
            <template v-slot:body>
              <p>
                Escolha um usuário ou um destinatário para criar uma delegação
                de acesso à
                <span class="font-weight-medium"> {{ email }}</span
                >.
              </p>
              <p>Esta ação pode ser revogada a qualquer momento.</p>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    ref="fromEmail"
                    label="De:"
                    placeholder="Selecione o e-mail do usuário desejado para delegar o acesso."
                    clearable
                    v-model="fromEmail"
                    :items="
                      selectEmail != email && selectEmail != null
                        ? [email]
                        : availableAccessUsers
                    "
                    item-text="email"
                    color="#311F50"
                    @focus="onInputFocus"
                    hide-no-data
                    :error-messages="
                      isInputTouched ? errorMessageFromEmail : ''
                    "
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    ref="selectEmail"
                    label="Para:"
                    placeholder="Selecione o e-mail do usuário desejado para receber o acesso."
                    clearable
                    v-model="selectEmail"
                    :items="
                      fromEmail != email && fromEmail != null
                        ? [email]
                        : availableUsersDelegated
                    "
                    item-text="email"
                    color="#311F50"
                    @focus="onInputFocus"
                    hide-no-data
                    :error-messages="isInputTouched ? errorMessage : ''"
                  >
                  </v-autocomplete>
                  <div class="d-flex align-center" v-if="!hasSign">
                    <v-icon color="accent" class="ml-0">mdi-crown</v-icon>
                    <span class="mt-1 ml-2 font-weight-medium body-2">
                      Data de expiração disponível a partir do plano Stantard
                    </span>
                  </div>
                  <v-menu
                    v-model="showExpireDate"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: onTooltip }">
                          <div v-on="!hasSign && onTooltip">
                            <v-text-field
                              class="mt-0"
                              v-model="formatedExpireDate"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              append-icon="mdi-close"
                              @click:append="expireDate = ''"
                              :disabled="!hasSign"
                            >
                              <template v-slot:label>
                                Data de expiração da delegação
                              </template>
                            </v-text-field>
                          </div>
                        </template>
                        <span>Disponível a partir do plano Standard</span>
                      </v-tooltip>
                    </template>
                    <v-date-picker
                      v-model="expireDate"
                      locale="pt-br"
                      :min="tomorrow"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </HeaderDialog>

          <!-- MODAL DE FEEDBACK -->
          <HeaderDialog
            v-if="userHasNotEvaluated"
            ref="form"
            id="user_feedback"
            title="Deixe seu feedback sobre a funcionalidade"
            :show="showUserFeedback"
            width="600"
            :persistent="false"
            custom-footer
          >
            <template v-slot:body>
              <v-row class="mx-0">
                <v-col class="mx-0">
                  <FeedbackRating
                    action="delegate_email"
                    showDismiss
                    @close="closeFeedback"
                  />
                </v-col>
              </v-row>
            </template>
          </HeaderDialog>

          <!-- FIM DIALOG ADICIONAR NOVO USER -->

          <!-- OVERLAY FOR SCOPES MISSING  -->
          <OverlayScopes
            :overlay="overlayScopes"
            :title="$t('login.accessDelegation')"
            :functionality="$t('login.accessDelegation')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import * as moment from "moment";
import { formatDate, renewFeedback } from "@/helpers/services/utils";
import { getUsers } from "@/helpers/services/getUsers";
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import FeedbackRating from "@/components/forms/FeedbackRating.vue";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  components: { HeaderDialog, FeedbackRating, OverlayScopes },
  name: "Delegate",
  props: {
    email: { type: String, default: "" },
    userKey: { type: String, default: "" },
    user_data: { type: Object, default: () => {} },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          sort: false,
          value: "to_user",
        },
        { text: "", width: 100, value: "expire" },
        { text: "", width: 60, align: "end", value: "more" },
      ],
      panel: [this.isPanelOpened ? 0 : 1],
      loading: false,
      showLoading: false,
      delegateEmails: [],
      delegateAccessEmails: [],
      dialogDeleteItem: false,
      dialogChangeExpireDate: false,
      snackbarMessage: "",
      snackbarColor: "",
      dialogAddDelegation: false,
      selectEmail: null,
      radioRuleReadMessage: "setAsReaded",
      radioRuleAddress: "showAddressSender",
      email_to_delete: null,
      email_to_expire: null,
      disabled: false,
      users: [],
      expireDate: "",
      showExpireDate: false,
      showNewExpireDate: false,
      tomorrow: moment().add(1, "day").format("YYYY-MM-DD"),
      userKeyToDelegation: this.userKey,
      tab: null,
      fromEmail: null,
      isAccessDelegation: false,
      isInputTouched: false,
      isDeletedEmail: false,
      showUserFeedback: false,
      overlayScopes: false,
    };
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    formatDate,
    renewFeedback,

    openAddDelegationDialog() {
      this.dialogAddDelegation = true;
    },

    onInputFocus() {
      this.isInputTouched = true;
    },

    clearDelegationData() {
      this.dialogAddDelegation = false;
      this.expireDate = "";
      this.fromEmail = null;
      this.selectEmail = null;
      this.isInputTouched = false;
    },

    getUser(email) {
      let user = this.users.find((user) => user.email === email);
      return user;
    },

    getUserName(email) {
      let user = this.users.find((user) => user.email === email);
      if (user) {
        return user.name;
      } else {
        return "Usuário";
      }
    },

    setEmailToExpire(delegation) {
      if (delegation.from_user === this.email) {
        this.email_to_expire = delegation.to_user;
      } else {
        this.email_to_expire = delegation.from_user;
      }
      this.SetisAccessDelegation(delegation);
    },

    setEmailToDelete(delegation) {
      if (delegation.from_user === this.email) {
        this.email_to_delete = delegation.to_user;
      } else {
        this.email_to_delete = delegation.from_user;
      }
      this.SetisAccessDelegation(delegation);
    },

    SetisAccessDelegation(delegation) {
      this.isAccessDelegation = delegation.to_user === this.email;
    },

    async getUsersDelegate() {
      const users = await getUsers();
      if (Array.isArray(users) && !!users.length) {
        this.users = users;
      }
    },
    getDelegate() {
      this.showLoading = true;
      this.disabled = true;

      const url_delegations = `${process.env.VUE_APP_API_BASE_URL}/users/${this.userKey}/delegate`;

      this.$axios
        .get(url_delegations, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.delegateEmails = response.data.delegates;
          this.disabled = false;
        })
        .catch((err) => {
          console.error("getDelegate()", err);
          this.disabled = true;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    changeExpireDate(newDate) {
      this.showLoading = true;

      let url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.userKeyToDelegation}/delegate`;

      if (this.isAccessDelegation) {
        let selectedUser = this.users.filter(
          (user) => user.email === this.email_to_expire
        );
        let user = this.users.filter((user) => user.key === this.userKey);
        this.userKeyToDelegation = selectedUser[0]["key"];
        this.email_to_expire = user[0]["email"];
        url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.userKeyToDelegation}/access_delegate`;
      } else {
        this.userKeyToDelegation = this.userKey;
      }

      let params = { email: this.email_to_expire, expire_date: newDate };
      let auth = { headers: { Authorization: this.token } };

      this.$axios
        .patch(url, params, auth)
        .then((response) => {
          this.delegateEmails = response.data.delegates;
          this.snackbarMessage = successMessages.changed_expire_date;
          this.snackbarColor = "success";
        })
        .catch((err) => {
          if ("DATE_UPDATE_ERROR".includes(err.response.data.code)) {
            this.snackbarMessage = errorMessages.DATE_UPDATE_ERROR;
            this.snackbarColor = "error";
          } else {
            this.snackbarMessage = errorMessages.unknown;
            this.snackbarColor = "error";
          }
        })
        .finally(() => {
          this.showLoading = false;
          this.selectEmail = null;
          this.setSnackBar({
            show: true,
            message: this.snackbarMessage,
            color: this.snackbarColor,
          });
        });
    },

    createDelegate() {
      this.showLoading = true;

      if (!this.isDelegation) {
        let selectedUser = this.users.filter(
          (user) => user.email === this.fromEmail
        );
        let user = this.users.filter((user) => user.key === this.userKey);
        this.userKeyToDelegation = selectedUser[0]["key"];
        this.selectEmail = user[0]["email"];
      } else {
        this.userKeyToDelegation = this.userKey;
      }

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.userKeyToDelegation}/delegate`;

      let params = { email: this.selectEmail, expire_date: this.expireDate };
      let auth = { headers: { Authorization: this.token } };

      if (this.expireDate == "") {
        params = { email: this.selectEmail };
      }

      this.$axios
        .post(url, params, auth)
        .then((response) => {
          if (response.data.code) {
            this.snackbarMessage = errorMessages.unknown;
            this.snackbarColor = "error";
          }
          this.snackbarMessage = successMessages.apply_24hours;
          this.snackbarColor = "success";
          this.getDelegate();
        })
        .catch((err) => {
          console.error("createDelegate()", err);
          this.setSnackBarError(err);
        })
        .finally(() => {
          this.showLoading = false;
          this.selectEmail = null;
          this.showUserFeedback = true;
          this.resetSnackBar();
        });
    },

    deleteDelegate(email_to_delete) {
      this.showLoading = true;
      this.dialogDeleteItem = false;

      if (this.isAccessDelegation) {
        let UserGivingAccess = this.users.find(
          (user) => user.email === this.email_to_delete
        );
        let user = this.users.find((user) => user.key === this.userKey);

        if (!UserGivingAccess) {
          this.userKeyToDelegation = user.key;
          this.isDeletedEmail = true;
        } else {
          this.userKeyToDelegation = UserGivingAccess.key;
          email_to_delete = user.email;
        }
      } else {
        this.userKeyToDelegation = this.userKey;
      }

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.userKeyToDelegation}/delegate`;

      let params = { email: email_to_delete };

      if (this.isDeletedEmail) {
        params = {
          email: email_to_delete,
          is_deleted_email: this.isDeletedEmail,
        };
      }

      this.$axios
        .delete(url, {
          headers: {
            Authorization: this.token,
          },
          data: params,
        })
        .then(() => {
          this.email_to_delete = null;
          this.snackbarMessage = successMessages.delete_24hours;
          this.snackbarColor = "success";
          this.getDelegate();
        })
        .catch((err) => {
          console.error("deleteDelegate", err);
          this.setSnackBarError(err);
        })
        .finally(() => {
          this.showLoading = false;
          this.resetSnackBar();
        });
    },
    closeFeedback() {
      this.showUserFeedback = false;
    },

    setSnackBarError(err) {
      this.snackbarColor = "error";
      const { unknown, failed_precondition } = errorMessages;
      this.snackbarMessage = unknown;
      if (err.response.data.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
        this.overlayScopes = true;
        return;
      } else if (
        "FAILED_PRECONDITION".includes(err.response.data.error.status)
      ) {
        this.snackbarMessage = failed_precondition;
      }
      this.setSnackBar({
        show: true,
        message: this.snackbarMessage,
        color: "error",
      });
    },
    resetSnackBar() {
      this.snackbarColor = "";
      this.snackbarMessage = "";
    },
  },

  watch: {
    panel(oldV, newV) {
      if (
        this.userHasGmail &&
        newV.length === 1 &&
        !this.delegateEmails.length
      ) {
        this.getUsersDelegate();
        this.getDelegate();
      }
    },
    fromEmail(newValue) {
      if (newValue !== this.email && newValue !== null) {
        this.selectEmail = this.email;
      }
    },
    selectEmail(newValue) {
      if (newValue !== this.email && newValue !== null) {
        this.fromEmail = this.email;
      }
    },
  },

  computed: {
    ...mapGetters([
      "token",
      "currentUser",
      "hasSecurity",
      "hasStandard",
      "hasBasic",
      "hasEnterprise",
      "hasSign",
    ]),

    userHasNotEvaluated() {
      if (this.currentUser.feedbacks) {
        return this.renewFeedback(this.currentUser.feedbacks.delegate_email);
      }
      return false;
    },

    userHasGmail() {
      return this.user_data ? this.user_data.is_gmail_enabled : false;
    },

    isDelegation() {
      return this.fromEmail === this.email;
    },

    validEmail() {
      if (!!this.selectEmail && this.selectEmail.length > 0) {
        return /.+@.+\..+/.test(this.selectEmail);
      } else {
        return false;
      }
    },
    validFromEmail() {
      if (!!this.fromEmail && this.fromEmail.length > 0) {
        return /.+@.+\..+/.test(this.fromEmail);
      } else {
        return false;
      }
    },
    errorMessage() {
      return !this.validEmail ? "Selecione um e-mail" : "";
    },
    errorMessageFromEmail() {
      return !this.validFromEmail ? "Selecione um e-mail" : "";
    },
    availableUsersDelegated() {
      const availableEmails = this.getDelegatedUsers.map((obj) => obj.to_user);
      return this.users.filter((user) => !availableEmails.includes(user.email));
    },
    availableAccessUsers() {
      const availableEmails = this.getAccessUsers.map((obj) => obj.from_user);
      return this.users.filter((user) => !availableEmails.includes(user.email));
    },

    formatedExpireDate() {
      return this.formatDate(this.expireDate);
    },
    getDelegatedUsers() {
      let delegatedUsers = this.delegateEmails.filter(
        (item) => item.from_user === this.email
      );
      return delegatedUsers;
    },
    getAccessUsers() {
      let delegatedUsers = this.delegateEmails.filter(
        (item) => item.to_user === this.email
      );
      return delegatedUsers;
    },
  },
};
</script>
